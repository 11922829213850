import Vue from 'vue'
import axios from 'axios'

const Url = 'https://api.lpqo.cn'
// const Url = 'http://localhost:777/api'

axios.defaults.baseURL = Url
Vue.prototype.$http = axios

export { Url }
