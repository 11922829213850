import Vue from 'vue'
import VueRouter from 'vue-router'
import schT from './schText.js'

import NotFound from './components/NotFound.vue'
import Diary from './views/Diary.vue'

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: Diary,
      name: '光影日记'
    },
    {
      path: '/link/:id',
      name: '链接跳转'
    },
    {
      path: '*',
      component: NotFound,
      name: '光影日记-错误'
    }
  ]
})
Vue.use(VueRouter)

router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.name
  }
  if (to.params.id && to.name == '链接跳转') {
    schT.schText = 'id ' + to.params.id
    next('/')
  }
  next()
})

export default router
