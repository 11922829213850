<template>
  <div class="letter">
    <h3>
      <el-link v-show="show" @click="reEdit()"
        ><i
          style="font-size: 18px; margin-top: -2px;"
          class="el-icon-arrow-left"
        ></i
      ></el-link>
      {{ letterLab }}
    </h3>
    <p v-show="!show" class="letterinf">
      <el-input
        placeholder="昵称（选填）"
        size="medium"
        style="width: 49%; font-size: 16px;"
        v-model="letter.possessor"
      ></el-input>
      <el-input
        placeholder="联系方式（选填）"
        size="medium"
        style="width: 49%; font-size: 16px;"
        v-model="letter.links"
      ></el-input>
    </p>
    <el-input
      v-show="!show"
      style="font-size: 16px;"
      placeholder="留言内容"
      type="textarea"
      :autosize="{ minRows: 3 }"
      v-model="letter.content"
    ></el-input>
    <p v-show="!show" class="letterhint">
      你的昵称与留言内容将会公开显示，但联系方式不会公开。如果你不想发布公开留言，也可以发送邮件到
      ljm@lpqo.cn 与我联系。
    </p>
    <div v-show="show" class="captcha">
      <img class="captchaimg" :src="captcha.captcha_url" />
      <el-input
        class="captchastr"
        placeholder="在此输入验证码"
        style="font-size: 16px;"
        size="medium"
        @keyup.enter.native="doCaptcha()"
        v-model="captcha.captcha_str"
      ></el-input>
    </div>
    <el-button @click="doCaptcha()" round size="small">完成</el-button>
    <div v-if="letterTotal != 0" class="content">
      <el-card
        style="margin-bottom: 7px;"
        v-for="i in list"
        :key="i.ID"
        :id="i.ID"
        shadow="never"
      >
        <p class="cinf">
          {{ i.possessor }} -
          {{ fmtDate(i.CreatedAt) }}
        </p>
        <p class="cc">{{ i.content }}</p>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Url } from '../plugins/axios.js'
export default {
  props: ['oid'],
  data() {
    return {
      foid: '',
      list: [],
      show: false,
      letterLab: '在此留言：',
      url: Url,
      letter: {
        objective_id: '',
        possessor: '',
        content: '',
        links: ''
      },
      letterTotal: 0,
      captcha: {
        captcha_url: '',
        captcha_id: '',
        captcha_str: ''
      }
    }
  },
  created() {
    this.getLetter()
  },
  watch: {
    oid() {
      this.getLetter()
    }
  },
  methods: {
    async getLetter() {
      const { data: res } = await this.$http.get('l', {
        params: {
          objective_id: this.oid
        }
      })
      if (res.status != 200)
        return this.$message({
          message: res.message,
          type: 'warning'
        })
      this.list = res.data
      this.letterTotal = res.total
    },
    async doCaptcha() {
      if (this.letter.content == '')
        return this.$message({
          message: '留言内容不能为空',
          type: 'warning'
        })
      if (this.letter.content.length > 300)
        return this.$message({
          message: '留言内容不能超过300字',
          type: 'warning'
        })
      if (this.letter.possessor.length > 50)
        return this.$message({
          message: '昵称不能超过50字',
          type: 'warning'
        })
      if (this.letter.links.length > 50)
        return this.$message({
          message: '联系方式不能超过50字',
          type: 'warning'
        })
      if (this.captcha.captcha_str.length != 0) {
        return this.doSend()
      }
      const { data: res } = await this.$http.get('captcha')
      if (res.status != 200) return this.$message('网络错误')
      this.captcha.captcha_url = this.url + res.captcha_img
      this.captcha.captcha_id = res.captcha_id
      this.letterLab = '最后一步：'
      this.show = true
    },
    async doSend() {
      this.letter.objective_id = this.oid
      const { data: res } = await this.$http.post('l', this.letter, {
        params: {
          captcha_id: this.captcha.captcha_id,
          captcha_str: this.captcha.captcha_str
        }
      })
      if (res.status != 200) {
        this.$message({
          message: res.message,
          type: 'warning'
        })
        this.captcha.captcha_str = ''
        this.doCaptcha()
        return
      }
      this.letter = {
        objective_id: '',
        possessor: '',
        content: '',
        links: ''
      }
      this.captcha = {
        captcha_url: '',
        captcha_id: '',
        captcha_str: ''
      }
      this.letterLab = '在此留言：'
      this.show = false
      this.$message({
        message: '已提交 该留言将在审核后公开',
        type: 'success',
        duration: 4000
      })
    },
    reEdit() {
      this.captcha = {
        captcha_url: '',
        captcha_id: '',
        captcha_str: ''
      }
      this.letterLab = '在此留言：'
      this.show = false
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  margin-top: 27px;
  text-align: left;
}

.cinf {
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei',
    'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #404040;
}

.cc {
  margin-top: 7px;
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei',
    'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #404040;
}

.letter {
  width: 700px;
  margin: 7px auto;
  text-align: center;
}

.letter h3 {
  text-align: left;
}

.letterinf {
  margin: 7px 0;
  display: flex;
  justify-content: space-between;
}

.letterhint {
  width: 470px;
  margin: 7px auto;
}

.captcha {
  width: 100%;
  height: 80px;
  margin: 7px auto;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
}

.captchastr {
  margin-left: 17px;
  width: 177px;
}

.letter >>> .el-button {
  background-color: #fff;
  border-color: #dcdfe5;
  color: #666;
}

.letter >>> .el-button:hover {
  background-color: #fff;
  border-color: #909398;
}

.letter >>> .el-button span {
  background-color: #fff;
}

.letter >>> .el-input__inner:focus,
.letter >>> .el-textarea__inner:focus {
  border: solid 1px #dcdfe6;
}

@media screen and (max-width: 1017px) {
  .letter {
    width: 100%;
  }

  .letterhint {
    width: 100%;
  }

  .captcha {
    display: flex;
    justify-content: space-between;
  }

  .captcha img {
    width: 50%;
  }

  .captcha div {
    width: 50%;
  }

  .captchastr {
    width: 40%;
  }
}
</style>
