<template>
  <footer>
    <div class="logo"></div>
    <div class="info">
      <p>
        <el-link
          :underline="false"
          style="font-size: 15px;  font-weight: 400;"
          href="/link/mWskvMuRj8"
          >关于网站</el-link
        >
      </p>
      <div class="upyun">
        <p>光影日记由</p>
        <a
          target="_blank"
          href="https://www.upyun.com/?utm_source=lianmeng&utm_medium=referral"
          ><div class="upyun_logo"></div
        ></a>
        <p>提供云存储服务</p>
      </div>
      <p copy>
        Copyright &copy;2019-{{ date }} blog.lpqo.cn
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      date: 'PRESENT'
    }
  },
  created() {
    this.getDate()
  },
  methods: {
    getDate() {
      fetch('https://api.lpqo.cn/time')
        .then(d => d.json())
        .then(res => {
          if (res.status == 200) this.date = new Date(res.time).getFullYear()
        })
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1017px) {
  footer {
    width: 1000px;
    height: 60px;
    padding: 0 27px;
    margin: 0px auto 7px;
    display: flex;
    justify-content: space-between;
  }
}

.logo {
  height: 50px;
  width: 50px;
  position: relative;
  top: 5px;
  background-image: url(../assets/lpqo_logo.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.upyun {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #404040;
}

.upyun_logo {
  height: 17px;
  width: 50.5px;
  background-image: url(../assets/upyun_logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 1px 0 1.5px;
  filter: opacity(65%);
}

.upyun_logo:hover {
  filter: opacity(80%);
}

.info {
  height: 57px;
  display: flex;
  justify-content: right;
}

.info > p, .info > div {
  margin-left: 27px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606266;
}

@media screen and (max-width: 1017px) {
  footer {
    height: 80px;
    text-align: center;
    width: 100%;
    padding: 0 2%;
    margin: 37px 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .logo {
    height: 40px;
    width: 40px;
  }

  .info {
    height: 80px;
    display: flex;
    flex-direction: column;
  }

  .info p {
    text-align: right;
    height: 20px;
    display: block;
    margin: 4px 0px;
    font-size: 15px;
  }

  .info > div {
    justify-content: right;
  }

  .info > p[copy] {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    transform: translateY(57px);
  }
}
</style>
