import Vue from 'vue'
import {
  Button,
  Icon,
  Input,
  Message,
  Timeline,
  TimelineItem,
  Card,
  Link,
  Backtop,
  Image,
  Carousel,
  CarouselItem,
  Popover,
  Loading,
  Collapse,
  CollapseItem
} from 'element-ui'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './element-ui-style/theme/index.css'

// Vue.use(ElementUI)

Vue.use(Button)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Card)
Vue.use(Link)
Vue.use(Backtop)
Vue.use(Image)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Loading)
Vue.use(Collapse)
Vue.use(CollapseItem)

Vue.prototype.$message = Message
