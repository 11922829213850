<template>
  <div class="diary">
    <div v-if="wd" :class="isFullDis ? 'full' : 'no_full'">
      <p class="full_load_test" v-show="fullLoadTest">
        <i class="el-icon-loading"></i>
      </p>
      <img
        v-show="!fullLoadTest"
        @load="fullLoadTest = false"
        :src="fullLink"
        @click="aboutFull"
      />
    </div>
    <header class="headerm">
      <Logo style="margin: 0 auto;" />
      <div class="navlist">
        <el-input
          v-model="schText"
          @keyup.enter.native="doSch()"
          @clear="reSetQuery(true)"
          type="text"
          class="input"
          size="small"
          placeholder="寻找光影日记"
          ref="sch"
        >
          <el-button
            @click="doSch()"
            icon="el-icon-search"
            class="search"
            slot="append"
          ></el-button>
        </el-input>
      </div>
    </header>
    <main>
      <div
        v-if="wd && list.length != 0"
        class="line"
        style="margin-bottom: 17px"
      ></div>
      <el-timeline>
        <el-timeline-item
          v-for="i in list"
          :key="i.ID"
          :id="i.ID"
          size="large"
          :timestamp="i.typeLable + ' ' + i.publish_dt"
          placement="top"
        >
          <el-card
            v-if="i.type < 2 || i.type > 6"
            :shadow="wd ? 'never' : 'hover'"
          >
            <el-collapse v-model="i.open" class="more">
              <el-collapse-item :name="i.ID">
                <template slot="title">
                  <p class="morehint">{{ i.title }}</p>
                </template>
                <div
                  class="notice-content el-tiptap-editor__content"
                  v-html="i.content"
                ></div>
              </el-collapse-item>
            </el-collapse>
            <p class="asum" v-if="i.summary.length != 0">
              {{ i.summary }}
            </p>
            <Bot :i="i" @doSch="doSch" />
          </el-card>
          <el-card v-if="i.type == 2" :shadow="wd ? 'never' : 'hover'">
            <div class="title" v-html="i.title"></div>
            <Bot :i="i" @doSch="doSch" />
          </el-card>
          <el-card v-if="i.type == 3" :shadow="wd ? 'never' : 'hover'">
            <p class="title">{{ i.title }}</p>
            <p class="summary" v-if="i.summary.length != 0">
              {{ i.summary }}
            </p>
            <el-carousel
              style="margin: 10px 0"
              trigger="click"
              v-if="i.fileDis.length != 0"
              :height="wd ? '' : '620px'"
              indicator-position="none"
              :arrow="i.fileDis.length == 1 ? 'never' : 'always'"
            >
              <el-carousel-item v-for="m in i.fileDis" :key="m.ID">
                <img
                  v-if="wd"
                  :src="m.links + '!w10'"
                  @click="inFull(m.links)"
                  style="width: 100%; height: 100%; object-fit: scale-down; cursor: pointer;"
                />
                <el-image
                  v-else
                  :src="m.links + '!w10'"
                  :preview-src-list="[m.links]"
                  style="width: 100%; height: 100%; cursor: pointer"
                  fit="scale-down"
                />
              </el-carousel-item>
            </el-carousel>
            <Bot :i="i" @doSch="doSch" />
          </el-card>
          <el-card
            v-if="i.type == 4 || i.type == 5 || i.type == 6"
            :shadow="wd ? 'never' : 'hover'"
          >
            <p class="title">{{ i.title }}</p>
            <p class="summary" v-if="i.summary.length != 0">
              {{ i.summary }}
            </p>
            <div v-if="i.type == 4">
              <video
                v-for="m in i.fileDis"
                :key="m.ID"
                style="margin: 7px 0; width: 100%"
                controls
              >
                <source :src="m.links" type="video/mp4" />
              </video>
            </div>
            <div v-if="i.type == 5">
              <audio
                v-for="m in i.fileDis"
                :key="m.ID"
                style="margin: 7px 0; width: 100%; outline: none"
                controls
              >
                <source :src="m.links" type="audio/mp3" />
              </audio>
            </div>
            <div v-if="i.type == 6">
              <div style="margin: 7px 0" v-for="m in i.fileDis" :key="m.ID">
                <el-link target="_blank" :href="m.links">{{ m.links }}</el-link>
              </div>
            </div>
            <Bot :i="i" @doSch="doSch" />
          </el-card>
          <div v-if="wd" class="line"></div>
        </el-timeline-item>
      </el-timeline>
      <div class="op">
        <el-button
          type="info"
          v-show="haveSch && loadText != '加载中'"
          @click="reSetQuery(true)"
          size="small"
        >
          返回首页
        </el-button>
        <el-button
          type="info"
          v-show="!(haveSch && loadText == '暂无更多')"
          @click="loadMore()"
          :loading="isLoad"
          size="small"
          >{{ loadText }}</el-button
        >
      </div>
    </main>
    <el-backtop class="backtop"></el-backtop>
    <div v-if="wd" class="linee"></div>
    <div style="height: 27px"></div>
    <Foot />
  </div>
</template>

<script>
import schT from '../schText.js'
import Foot from '../components/Foot.vue'
import Bot from '../components/Bot.vue'
import Logo from '../components/TextLogo.vue'

export default {
  components: { Foot, Bot, Logo },
  data() {
    return {
      fullLoadTest: true,
      isFullDis: false,
      top: 0,
      fullLink: '',
      schText: '',
      loadText: '查看更多',
      isLoad: false,
      query: {
        id: '',
        pagesize: 7,
        pagenum: 1,
        title: '',
        summary: '',
        tag_id: '',
        tag_name: '',
        type: ''
      },
      list: [],
      listTotal: 0,
      screenWidth: document.body.clientWidth,
      wd: false,
      haveSch: false
    }
  },
  created() {
    this.loadList()
    if (document.body.clientWidth < 1017) this.wd = true
    else this.wd = false
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth() {
      if (document.body.clientWidth < 1017) this.wd = true
      else this.wd = false
    }
  },
  methods: {
    doSch(inSchText) {
      var st = inSchText == undefined ? this.schText : inSchText
      if (st.length == 0) return
      if (this.$refs.sch != undefined) this.$refs.sch.blur()
      this.reSetQuery(false)
      this.schText = st
      var stLen = st.length
      var stLab = st.substring(0, 3)
      switch (stLab) {
        case '文章 ':
          this.query.type = 1
          this.query.title = st.substring(3, stLen)
          break
        case '随笔 ':
          this.query.type = 2
          this.query.title = st.substring(3, stLen)
          break
        case '照片 ':
          this.query.type = 3
          this.query.title = st.substring(3, stLen)
          break
        case '视频 ':
          this.query.type = 4
          this.query.title = st.substring(3, stLen)
          break
        case '音频 ':
          this.query.type = 5
          this.query.title = st.substring(3, stLen)
          break
        case '文件 ':
          this.query.type = 6
          this.query.title = st.substring(3, stLen)
          break
        case 'id ':
        case 'ID ':
          this.query.id = st.substring(3, stLen)
          break
        case '标题 ':
          this.query.title = st.substring(3, stLen)
          break
        case '简介 ':
          this.query.summary = st.substring(3, stLen)
          break
        case '标签 ':
          this.query.tag_name = st.substring(3, stLen)
          break
        default:
          this.query.title = st
      }
      this.haveSch = true
      this.loadList()
    },
    loadMore() {
      this.query.pagenum++
      this.loadList()
    },
    reSetQuery(reLoad) {
      this.query = {
        id: '',
        pagesize: 7,
        pagenum: 1,
        title: '',
        summary: '',
        tag_id: '',
        tag_name: '',
        type: ''
      }
      this.list = []
      this.haveSch = false
      window.scrollTo(0, 0)
      this.listTotal = 0
      if (reLoad) {
        this.schText = ''
        this.loadList()
      }
    },
    async loadList() {
      if (schT.schText != '') {
        this.schText = schT.schText
        schT.schText = ''
        return this.doSch()
      }
      this.isLoad = true
      this.loadText = '加载中'
      const { data: res } = await this.$http.get('h', { params: this.query })
      if (res.status != 200) return this.$message(res.message)
      res.data.forEach(async el => {
        el.letter = ''
        el.open = ''
        if (res.total == 1) {
          el.open = el.ID
        }
        el.tagList = []
        this.getTagList(el.ID)
        el.fileDis = []
        if (el.type > 2) this.getFileList(el.ID)
        el.publish_dt = this.fmtDate(el.publish_dt, '暂 无', false)
        el.typeLable = ['未知', '文章', '随笔', '照片', '视频', '音频', '文件'][
          el.type
        ]
      })
      this.list = this.list.concat(res.data)
      this.listTotal = res.total
      if (this.query.id != '' && res.total == 0) {
        this.$message({
          message: '请检查ID是否正确',
          type: 'warning'
        })
      } else if (res.total == 0) {
        this.$message({
          message: '没有找到任何有关日记',
          type: 'warning'
        })
      }
      if (this.list.length == this.listTotal) {
        this.loadText = '暂无更多'
      } else this.loadText = '查看更多'

      this.isLoad = false
    },
    getTagList(id) {
      this.$http
        .get('t', {
          params: {
            objective_id: id
          }
        })
        .then(res => {
          this.list.forEach(el => {
            if (el.ID == id) {
              el.tagList = res.data.data
            }
          })
        })
    },
    getFileList(id) {
      this.$http
        .get('f', {
          params: {
            objective_id: id
          }
        })
        .then(res => {
          this.list.forEach(el => {
            if (el.ID == id) {
              el.fileDis = res.data.data
            }
          })
        })
    },
    inFull(link) {
      this.fullLink = link
      this.top = document.documentElement.scrollTop
      this.isFullDis = true
      function bodyScroll(event) {
        event.preventDefault()
      }
      document.body.addEventListener('touchmove', bodyScroll, false)
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    },
    aboutFull() {
      this.isFullDis = false
      this.fullLink = ''
      this.fullLoadTest = true
      function bodyScroll(event) {
        event.preventDefault()
      }
      document.body.removeEventListener('touchmove', bodyScroll, false)
      document.body.style.position = 'initial'
      document.body.style.width = 'auto'

      window.scrollTo(0, this.top)
    }
  }
}
</script>

<style scoped>
.no_full {
  display: none;
}

.full {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
}

.full img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.full_load_test {
  width: 100%;
  margin-top: 17%;
  text-align: center;
  font-size: 27px;
}

main {
  width: 1000px;
  min-height: 70vh;
  margin: 37px auto 0;
}

.content {
  height: 400px;
  background-color: antiquewhite;
}

.op {
  width: 100%;
  text-align: center;
}

.title {
  width: 100%;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  color: #666;
}

.more {
  border: none;
  margin: -10px 0;
}

.more >>> .el-collapse-item__header {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  color: #666;
  border: none;
  cursor: auto;
}

.more >>> .el-collapse-item__wrap {
  border: none;
}

.more >>> .el-icon-arrow-right {
  display: none;
}

.more >>> .el-collapse-item__content {
  padding-bottom: 7px;
}

.morehint {
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.morehint:hover {
  border-bottom: 1px solid #666;
}

.filedis {
  float: right;
  width: 77px;
  height: 77px;
}

.summary {
  margin: 7px 0;
  font-size: 16px;
  border-left: #e4e7ec 2.7px solid;
  padding-left: 7px;
}

.asum {
  margin: 17px 0;
  font-size: 15px;
  border-left: #e4e7ec 2.7px solid;
  padding-left: 7px;
}

.headerm {
  width: 100%;
  padding-top: 2.7%;
}

.navlist {
  width: 360px;
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
}

.input {
  width: 297px;
}

.input >>> .el-input__inner {
  font-size: 16px;
  height: 35px;
  font-weight: 300;
}

.input >>> .el-input__inner:focus {
  border: solid 1px #dcdfe6;
}

.search {
  padding: 5px;
  text-align: center;
  width: 50px;
  height: 35px;
}

.op >>> .el-button {
  background-color: #fff;
  border-color: #dcdfe5;
  color: #666;
}

.op >>> .el-button:hover {
  background-color: #fff;
  border-color: #909398;
}

.op >>> .el-button span {
  background-color: #fff;
}

.line {
  display: none;
  position: relative;
  right: 2%;
  width: 104%;
  height: 1px;
  background-color: #e6e6e6;
}

.linee {
  display: none;
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}

@media screen and (max-width: 1017px) {
  .headerm {
    padding-top: 8.7%;
  }

  .line, .linee {
    display: block;
  }

  .backtop {
    display: none;
  }

  .morehint {
    border-bottom: 1px solid #666;
  }

  .op {
    margin-bottom: 17px;
  }

  .op >>> .el-button {
    border: none;
    font-size: 15px;
  }

  main {
    width: 100%;
    min-height: 0;
    padding: 0 2%;
  }

  main >>> .el-timeline-item__tail,
  main >>> .el-timeline-item__node {
    display: none;
  }

  main >>> .el-timeline-item__wrapper {
    padding: 0;
  }

  main >>> .el-card__body {
    padding: 7px 0;
  }

  main >>> .el-card {
    border: none;
    border-radius: 0;
    padding-bottom: 7px;
  }

  main >>> .el-timeline-item {
    padding-bottom: 14px;
  }
}
</style>
