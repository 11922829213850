<template>
  <div class="bott">
    <div class="bot">
      {{ tagList }}
      <div class="bottag">
        <el-link
          v-for="t in i.tagList"
          class="tag"
          @click="toTag(t.title)"
          :key="t.ID"
          :underline="false"
          icon="el-icon-collection-tag"
          >{{ t.title }}</el-link
        >
      </div>
      <div class="botop">
        <el-link
          @click="ii.letter == i.ID ? (ii.letter = '') : (ii.letter = i.ID)"
          icon="el-icon-s-comment"
          style="margin-right: 7px"
          :underline="false"
          >留言
        </el-link>
        <el-link
          icon="el-icon-share"
          :underline="false"
          @click="share(i.ID, i.title)"
          >分享
        </el-link>
      </div>
    </div>

    <el-collapse v-model="ii.letter" class="letter more">
      <el-collapse-item :name="i.ID">
        <Letter :oid="i.ID" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import Letter from '../components/Letter.vue'

export default {
  components: { Letter },
  props: ['i'],
  data() {
    return {
      ii: this.i
    }
  },
  methods: {
    share(id, title) {
      if (this.i.type == 2) title = '随笔'
      var that = this
      let val = '光影日记-' + title + '：https://blog.lpqo.cn/link/' + id
      this.$copyText(val).then(
        function() {
          that.$message.success('已复制分享链接')
        },
        function() {
          that.$message.error('分享链接复制失败')
        }
      )
    },
    toTag(title) {
      this.$emit('doSch', '标签 ' + title)
    }
  }
}
</script>

<style scoped>
.botop {
  width: 119px;
}

.bottag {
  width: 870px;
}

.tag {
  margin-right: 7px;
  font-size: 14px;
}

.bot {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.more {
  border: none;
  margin: -10px 0;
}

.letter >>> .el-collapse-item__header {
  height: 20px;
}

.more >>> .el-collapse-item__header {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  color: #666;
  border: none;
  cursor: auto;
}

.more >>> .el-collapse-item__wrap {
  border: none;
}

.more >>> .el-icon-arrow-right {
  display: none;
}

.more >>> .el-collapse-item__content {
  padding-bottom: 7px;
}

@media screen and (max-width: 1017px) {
  .bot {
    display: block;
  }

  .botop {
    width: 100%;
    display: flex;
    text-align: right;
    justify-content: flex-end;
  }

  .bottag {
    display: block;
    width: 100%;
    /* margin: 0 0 7px; */
    /* background-color: #f5f7fa; */
    /* padding: 3px; */
    border-radius: 2px;
    /* display: flex;
    text-align: right;
    justify-content: flex-end; */
  }
}
</style>
